import React from "react";

const NotFound = (): React.ReactElement => {
  return (
    <div style={{ textAlign: "center" }}>
      <h1>404 Not Found 🙁</h1>
    </div>
  );
};

export default NotFound;
