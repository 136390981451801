export const HOME_PAGE = "/";

export const LOGIN_PAGE = "/login";

export const SIGNUP_PAGE = "/signup";

export const EDIT_TEAM_PAGE = "/edit-team";

export const DISPLAY_ENTITY_PAGE = "/entity";

export const CREATE_ENTITY_PAGE = "/entity/create";

export const UPDATE_ENTITY_PAGE = "/entity/update";

export const HOOKS_PAGE = "/hooks";
